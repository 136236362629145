<template>
  <v-bottom-sheet
    v-model="toggle"
    :fullscreen="fullscreen"
    hide-overlay 
    transition="slide-y-reverse-transition"
    open-delay="250"
    @click:outside="closeView"
    @keydown.esc="closeView"
  >
    <v-sheet
      id="doc-view"
      max-height="100%"
      class="sheet pa-4 fill-height d-flex flex-column"
    >      
      <v-fab-transition>
        <v-btn
          absolute
          top
          right
          fab
          small
          class="close-btn elevation-2 mt-8"
          @click="closeView()"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-window
        v-model="doc"
        height="360"
        hide-delimiters
        show-arrows-on-hover
        show-arrows
        continuous
        class="fill-height"
      >
        <v-window-item
          v-for="(doc, d) in docs"
          :key="d"
          :value="d"
          class="fill-height"
        >
          <v-row
            align="center"
            justify="center"
            class="fill-height"
          >
            <v-card
              :width="fullscreen ? '84vw' : '25vw'"
              min-height="16rem"
              class="mx-auto fill-height my-2"
            >
              <v-btn
                absolute
                top
                left
                fab
                small
                class="open-btn elevation-2 mt-8"
                @click="openDoc(doc.url_midia)"
              >
                <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
              </v-btn>
              <v-btn
                absolute
                top
                right
                fab
                small
                class="fullscreen-btn elevation-2 mt-8"
                @click="fullscreen = !fullscreen"
              >
                <v-icon>{{ fullscreen ? icons.mdiFullscreenExit : icons.mdiFullscreen }}</v-icon>
              </v-btn>
              <v-img 
                v-if="doc.url_midia!=''"
                :src="doc.url_midia!='' ? doc.url_midia : null"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                min-height="16rem"
                height="100%"
                contain
                class="photo fill-height white--text align-end rounded"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      :size="36"
                      :width="4"
                      color="primary"
                      indeterminate
                      style="opacity: .75"
                    />
                  </v-row>
                </template>
                <v-card-title>{{ doc.title | decodeWeek }}</v-card-title>
                <v-speed-dial
                  v-model="feedback[d].action"
                  absolute
                  bottom
                  right
                  small
                  open-on-hover
                >
                  <template v-slot:activator>
                    <v-btn
                      fab
                      small
                      :loading="doc.loading"
                    >
                      <v-icon>{{ doc.status==1 ? icons.mdiPencilOutline : doc.status==2 ? icons.mdiCheck : icons.mdiClose }}</v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    small
                    color="error"
                    :loading="doc.loading"
                    @click.stop="toggleFeedback(d, true)"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    color="success"
                    :loading="doc.loading"
                    @click.stop="sendDocFeedback(doc, 2)"
                  >
                    <v-icon>{{ icons.mdiCheck }}</v-icon>
                  </v-btn>
                </v-speed-dial>
                <v-fade-transition>
                  <v-overlay
                    v-if="feedback[d].toggle"
                    absolute
                  >
                    <v-textarea
                      v-model="feedback[d].text"
                      :label="$tc('general.feedback', 1)"
                      light
                      solo
                      hide-details
                      style="min-width: 12rem"
                    ></v-textarea>
                    <div class="actions d-flex">
                      <v-switch
                        v-model="feedback[d].required"
                        :false-value="0"
                        :true-value="1"
                        :label="$t(feedback[d].required ? 'general.required' : 'general.optional')"
                      />
                      <v-spacer />
                      <v-btn
                        fab
                        small
                        color="grey"
                        class="mt-3 mr-2"
                        @click="feedback[d].toggle = false;"
                      >
                        <v-icon>{{ icons.mdiEye }}</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        small
                        color="primary"
                        :loading="doc.loading"
                        class="mt-3"
                        @click="sendDocFeedback(doc, 3)"
                      >
                        <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
                      </v-btn>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </v-img>
              <div 
                v-else
                class="d-flex flex-column align-center justify-center"
                style="min-height: 24rem"
              >
                <v-icon 
                  color="grey"
                  size="36"
                  class="icon-pending rounded-circle"
                >
                  {{ icons.mdiClockOutline }}
                </v-icon>
                <span 
                  class="body-1 my-8"
                >
                  {{ doc.title | decodeWeek }}
                </span>
                <v-switch
                  v-model="feedback[d].required"
                  :false-value="0"
                  :true-value="1"
                  :loading="doc.loading"
                  :label="$t(feedback[d].required ? 'general.required' : 'general.optional')"
                  @change="sendDocFeedback(doc, (feedback[d].required ? 3 : 0))"
                />
              </div>
            </v-card>
          </v-row>
        </v-window-item>
      </v-window>

      <!-- <v-card-actions 
        class="controls pa-4"
      >
        <v-btn 
          block
          color="accent"
          class="btn-photo grey--text text--darken-4"
          large
          :loading="!!doc ? doc.loading : false"
          @click="takePhoto"
        >
          Tirar foto
        </v-btn>
      </v-card-actions> -->
    </v-sheet>
  </v-bottom-sheet>
</template>

<style>

  #doc-view {
    position: relative;
    height: 100% !important;
  }

  #doc-view.sheet {
    /* background: var(--mobees-black); */
  }

  #doc-view .v-toolbar {
    z-index: 1;
  }

  #doc-view .content {
    padding: 16px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 56px);
  }

  #doc-view .photo {
    max-height: calc(100vh - 56px);
  }

  #doc-view .icon-camera {
    border: 4px dashed var(--secondary-border);
  }

  #doc-view .controls {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .75; */
  }

</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiHelpCircleOutline,
    mdiClockOutline,
    mdiCheck,
    mdiAlertCircleOutline,
    mdiChevronRight,
    mdiPencilOutline,
    mdiContentSaveOutline,
    mdiEye,
    mdiFullscreen,
    mdiFullscreenExit,
    mdiOpenInNew
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { get, sync } from 'vuex-pathify'
  import _ from 'lodash';


  export default {
    name: 'DocView',

    props: {
      toggle: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      docs: {
        type: Object,
        default: () => null,
      },
      selected: {
        type: String,
        default: null,
      }
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiClockOutline,
        mdiCheck,
        mdiAlertCircleOutline,
        mdiChevronRight,
        mdiPencilOutline,
        mdiContentSaveOutline,
        mdiEye,
        mdiFullscreen,
        mdiFullscreenExit,
        mdiOpenInNew
      },
      feedback: {},
      doc: '',
      confirm: {
        toggle: false,
        loading: false,
        ok: true
      },
      fullscreen: false
    }),

    computed: {
      views: sync('app/views'),
      help: get('help/sections'),
      toast: sync('app/toast'),

      title () {
        return this.doc != null ? this.doc.title : '';
      },
      subtitle () {
        return this.doc != null ? this.doc.subtitle : '';
      },

      toolbarElevation () {
        return this.offsetTop >= 16 ? 2 : 0;
      },
    },

    watch: {
      selected: {
        immediate: true,
        deep: true,
        handler: function (d) {
          this.doc = d;
        }
      },
      docs: {
        immediate: true,
        deep: true,
        handler: function (docs) {
          this.feedback = _.mapValues(docs, doc => {
            return {
              toggle: false,
              action: false,
              required: doc.required,
              text: doc.feedback,
            }
          });
          this.confirm.toggle = this.verifiedDocs();
        }
      },
      toggle (v) {
        // if (false) this.toggleView();
      }
    },

    filters: {
      decodeWeek (text) {
        if (!_.isNil(text)&&text.indexOf('*')>=0) {
          const breakdown = text.split('*');
          const n = parseInt(breakdown[1].substr(-1, 1));
          const week = 'Semana anterior '+n;
          return text.replace('*week-'+n+'*', week);
        }else{
          return text;
        }
      }
    },

    methods: {
      ...services,

      verifiedDocs () {
        return _.every(this.docs, doc => {
          if (doc.status==3) this.confirm.ok = false;
          return doc.required==1 ? (doc.status==2 || doc.status==3) : true;
        });
      },

      toggleDoc (doc) {
        this.selected = doc;
      },
      openDoc (url) {
        window.open(url, '_blank');
      },

      toggleFeedback (doc, b) {
        this.feedback[doc].toggle = b;
        if (!b) this.feedback[doc].action = false;
      },

      sendDocFeedback (doc, status) {
        const d = doc.criteria;
        this.toggleFeedback(d, false);
        doc = {
          criteria: d,
          status: status,
          required: this.feedback[d].required,
          feedback: this.feedback[d].text,
        }
        this.$emit('feedback', doc);
      },

      validateDocs () {
        this.$emit('validate', this.confirm.ok);
      },
      
      closeView (b) {
        // this.toggle = b;
        this.$emit('close');
      },

      docPending (status) {
        return status==0 || status==3;
      },

      toggleHelp: function (b,c) {
        this.views.help.toggle = b; 
        this.views.help.context = typeof c != 'undefined' ? c : null;

        if (b) this.trackPage('help', {
          hash: c,
          title: this.help[c].title
        });
      },
      
      trackPage (page, subroute) {
        const route = subroute ? '/'+page+'/'+subroute.hash : '';
        const title = subroute ? this.views[page].title + ': ' + subroute.title : this.views[page].title;
        // track analytics
        this.$gtag.page({
          page: route,
          title: title + ' | Parceiros'
        });
        console.log(this.views[page].title + ' | Parceiros');
      },

    },

    mounted () {

    },

  }
</script>
